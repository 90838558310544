<template>
  <div class="pageBt">
    <div class="caijin_info">
      <div class="info_box">
        <div class="title">
          我们用心倾听，乐于交流，期待能成为您值得信赖的伙伴！
        </div>
        <div class="info_content">
          <div class="left">
            <div class="list">
              <img src="@/assets/navbar/icon1.png" alt="">
              联系电话：0546-7269603
            </div>
            <div class="list">
              <img src="@/assets/navbar/icon2.png" alt="">
              公司地址：山东省东营市东营区一路337号
            </div>
            <div class="list">
              <img src="@/assets/navbar/icon3.png" alt="">
              邮箱：dycjjt@126.com
            </div>
          </div>
          <img class="right" src="@/assets/navbar/bottom_logo.png" alt="">
        </div>
      </div>
    </div>
    <div class="caijing_bottom">
      <span>Copyright © 2022</span>
      <span>东营市财金投资集团</span>
      <span>鲁ICP备17047318号</span>
      <span>技术支持： 智慧（东营）大数据有限公司</span>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.pageBt {
  .caijin_info{
    width: 100%;
    height: 330px;
    background: url('@/assets/navbar/nav_bg.png') no-repeat center;
    background-size: 100% 100%;
    .info_box{
      margin: auto;
      width: 1400px;
      .title{
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 700;
        font-size: 30px;
        color: #333333;
        letter-spacing: 1px;
        text-align: left;
        padding-top: 40px;
      }
      .info_content{
        margin-top: 40px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .left{
          height: 141px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: space-between;
          .list{
            display: flex;
            align-items: center;
            img{
              margin-right: 5px;
              width: 20px;
              height: 20px;
            }
          }
        }
        .right{
          width: 371px;
          height: 61px;
        }
      }
    }
  }
  .caijing_bottom{
    width: 100%;
    height: 98px;
    background: #121D25;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    color: #FFFFFF;
    span{
      margin: 0 14px;
    }
  }
}
</style>