import axios from 'axios'
import { MessageBox, Message } from 'element-ui'
import store from '@/store'
import router from '@/router'


// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
  timeout: 5000 // request timeout
})

// 添加请求拦截器
service.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token")
    if (token) {
      config.headers['Authorization'] = 'Bearer ' + token // 让每个请求携带自定义token 请根据实际情况自行修改
    }
    // if (store.getters.token) {

    // }
    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(response => {
  // 二进制数据不拦截
  if (response.request.responseType === 'blob' || response.request.responseType === 'arraybuffer') {
    return response.data
  }

  const res = response.data
  // console.log(res,'拦截器');
  if (res.code !== 200) { // 异常状态码返回处理
    
    if(res.code==401) {
      // Message({
      //   message: '登录状态失效，请重新登录',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      if(localStorage.getItem("token")) {
        localStorage.clear()
        router.go(0)
      }
    }else{
      Message({
        message: res.msg || 'Error',
        type: 'error',
        duration: 5 * 1000
      })
    }
    return res.msg
  } else {
    return res
  }

},
error => {

  Message({
    message: error.msg,
    type: 'error',
    duration: 5 * 1000
  })
  return  error
}
)

export default service
