<template>
  <div class="home">
    <img class="logo" src="@/assets/login/login_logo.png" alt="">
    <div class="nav_bottom">
      Copyright © 2022 东营市财金投资集团 鲁ICP备17047318号 技术支持： 智慧（东营）大数据有限公司
    </div>
    <div class="asheet_form_box">
      <div class="form_box" v-if="current==0">
        <div class="tabs_box">
          <div :class="current==index?'tabs_list active':'tabs_list'" v-for="(item ,index) in tabs" @click="tabsChange(index)">
            {{ item.label }}
            <div class="line" v-if="current==index"></div>
          </div>
        </div>
        <div class="form_content">
          <div class="input_item_box">
            <img src="@/assets/login/account.png" alt="" class="icon">
            <div class="line"></div>
            <input class="input" type="text" v-model="formData.username" placeholder="请输入登录账号" />
          </div>
          <div class="input_item_box marT30">
            <img src="@/assets/login/psw.png" alt="" class="icon">
            <div class="line"></div>
            <input class="input" :type="pswType" v-model="formData.password" placeholder="请输入登录密码" />
            <img v-if="pswType=='password'" @click="changeEye" src="@/assets/login/close-eye.png" alt="" class="eye">
            <img v-else src="@/assets/login/eye.png" @click="changeEye" alt="" class="eye">
          </div>
          <div class="forget_box">
            <div class="forget" @click="forget">
              忘记密码？
            </div>
          </div>
          <div class="login_btn psw" @click="login">
            登录
          </div>
          <div class="register_box">
            若无账号，<span class="register" @click="register">请先注册</span>
          </div>
        </div>
      </div>

      <div class="form_box" v-if="current==1">
        <div class="tabs_box">
          <div :class="current==index?'tabs_list active':'tabs_list'" v-for="(item ,index) in tabs" @click="tabsChange(index)">
            {{ item.label }}
            <div class="line" v-if="current==index"></div>
          </div>
        </div>
        <div class="form_content">
          <div class="input_item_box">
            <img src="@/assets/login/phone.png" alt="" class="icon">
            <div class="line"></div>
            <input class="input" type="text" v-model="formData.mobile" placeholder="请输入手机号" />
          </div>
          <div class="input_item_box marT30">
            <img src="@/assets/login/verify.png" alt="" class="icon">
            <div class="line"></div>
            <input class="input" type="text" v-model="formData.smsCode" placeholder="请输入验证码" />
            <div :class="isClick?'verify_msg':'verify_msg disabled'" @click="sendCode()">{{ tips }}</div>
          </div>
          <div class="login_btn" @click="login">
            登录
          </div>
        </div>
      </div>
        <!-- <div class="login_select">
          <span @click="$routerGo('/reg')">用户注册</span>
          <span @click="$routerGo('/forget')">忘记密码</span>
        </div> -->
    </div>
  </div>
</template>

<script>
import { login, getUserInfo, smsLogin, sendCode } from "@/utils/network.js";
export default {
  data() {
    return {
      tabs: [
        { label: '密码登录' },
        { label: '验证码登录' }
      ],
      current: 0,
      formData: {
        mobile: '',
        password: '',
      },
      pswType: 'password',
      tips: '发送验证码',
      isClick: true,
      rules: {
        phone: [{ required: true, message: "请输入", trigger: "blur" }],
        password: [{ required: true, message: "请输入", trigger: "blur" }],
      },
      timer: null,
      second: 60
    }
  },
  methods: {
    forget() {
      this.$router.push('/forget')
    },
    register() {
      this.$router.push('/register')
    },
    sendCode() {
      if(this.isClick) {
        this.tips = '60秒可重新发送'
        this.isClick = false

        if(!this.formData.mobile) return this.$message.error('请输入手机号')
        const params = {
          mobile: this.formData.mobile
        }
        sendCode(params).then(res => {
          if(res.code==200) {
            this.$message.success('验证码已发送')
            this.formData.uuid = res.uuid
          }
        })

        this.timer = setInterval(() => {
          this.second = this.second - 1
          if(this.second==0) {
            clearInterval(this.timer)
            this.timer = null
            this.tips = '发送验证码'
            this.second = 60
            this.isClick = true
          }else{
            this.tips = this.second + '秒可重新发送'
          }
        }, 1000)
      }
    },
    tabsChange(index) {
      this.current = index
      this.pswType = 'password'
      this.formData = {}
    },
    changeEye() {
      if(this.pswType == 'password') {
        this.pswType = 'text'
      }else{
        this.pswType = 'password'
      }
    },
    login() {
      if(this.current == 0) {
        const params = {
          username: this.formData.username,
          password: this.formData.password,
        }
        login(params).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("token", res.token)
            this.getUserInfo()
          }
        })
      }else{
        const params = {
          mobile: this.formData.mobile,
          uuid: this.formData.uuid,
          smsCode: this.formData.smsCode
        }
        smsLogin(params).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("token", res.token)
            this.getUserInfo()
          }
        })
      }
      
    },
    async getUserInfo() {
      const res = await getUserInfo()
      this.$message({
        message: "登录成功",
        type: "success",
      })
      localStorage.setItem("userInfo", JSON.stringify(res.user))
      this.$router.replace("/")
    }
  }
}
</script>

<style  scoped lang="scss">
input::placeholder {
  font-family: Microsoft YaHei, Microsoft YaHei;
  font-weight: 400;
  font-size: 14px;
  color: #A3A5A7;
}
.home{
  width: 100%;
  height: 100vh;
  background: url('@/assets/login/login_bg.png') no-repeat center;
  background-size: 100% 100%;
  position: relative;
  .logo{
    position: absolute;
    margin: auto;
    left: 60px;
    top: 60px;
    width: 370px;
    height: 61px;
  }
  .nav_bottom{
    position: absolute;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient( 180deg, rgba(26,73,120,0) 0%, rgba(30,84,137,0.42) 100%);
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 16px;
    text-align: center;
    color: #333;
    padding-top: 60px;
  }
  .asheet_form_box {
    position: absolute;
    margin: auto;
    left: 50%;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    .form_box{
      background: #fff;
      width: 540px;
      height: 566px;
      box-shadow: -2px 5px 20px 0px rgba(225,221,214,0.66);
      border-radius: 10px;
      padding: 60px;
      .tabs_box{
        padding: 0 20px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        .tabs_list{
          position: relative;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 22px;
          color: #999;
          letter-spacing: 1px;
          height: 40px;
          cursor: pointer;
          &.active{
            font-weight: 700;
            font-size: 22px;
            color: #333;
            letter-spacing: 1px;
          }
          .line{
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            width: 60px;
            height: 2px;
            background: #C29145;
          }
        }
      }
      .form_content{
        margin-top: 60px;
        .input_item_box{
          width: 420px;
          background: #F6F6F6;
          padding: 15px 20px;
          display: flex;
          align-items: center;
          &.marT30{
            margin-top: 30px;
          }
          .icon{
            width: 24px;
            height: 24px;
          }
          .line{
            width: 1px;
            margin-left: 20px;
            height: 24px;
            background: #D8D8D8;
          }
          .input{
            flex: 1;
            width: 10px;
            margin-left: 30px;
            border: none;
            background: #F6F6F6;
            outline: none;
          }
          .eye{
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
          .verify_msg{
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 16px;
            color: #C29145;
            white-space: nowrap;
            cursor: pointer;
            &.disabled{
              cursor: no-drop;
            }
          }
        }
        .forget_box{
          margin-top: 30px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 16px;
          color: #A3A5A7;
          display: flex;
          justify-content: flex-end;
          .forget{
            cursor: pointer;
          }
        }
        .login_btn{
          width: 420px;
          height: 54px;
          background: linear-gradient( 180deg, #FDBA52 0%, #C29145 100%);
          color: #fff;
          cursor: pointer;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 18px;
          text-align: center;
          line-height: 54px;
          margin-top: 111px;
          &.psw{
            margin-top: 60px;
          }
        }
        .register_box{
          text-align: center;
          margin-top: 20px;
          .register{
            color: #C29145;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>