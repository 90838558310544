<template>
  <div>
    <div class="banner" ref="targetElement">
      <div class="top">
        <PageHd :show="true" />
      </div>
      <el-carousel>
        <el-carousel-item v-for="(item, index) in bannerList" :key="index">
            <img class="bannerIMG" :src="item.photoId" alt="">
          </el-carousel-item>
      </el-carousel>
    </div>
  </div>
</template>

<script>
import { getBannerList } from "@/utils/network.js";
import PageHd from './PageHd'
export default {
  name: 'PageScroll',
  components: {
    PageHd
  },
  data() {
    return {
      path: "/",
      isAtTop: false, // 用于判断是否在顶部
      showOtherContent: false, // 控制其他内容的显示
      canExpandHeight: false, // 标记是否可以恢复高度
      scrollTimeout: null, // 用于处理1秒的延迟
      isAnimating: false, // 标记高度变化是否正在进行
      fixed: false,


      fullScreen: true,
      isFirst: true,
      isTop: false,
      wait: false,
      isAnimating: false,
      bannerList: []
    }
  },
  created() {
    this.path = this.$route.path;
    this.getBannerList()
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('wheel', this.handleWheel, { passive: false }); // 监听鼠标滚轮事件，设置为非被动模式
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('wheel', this.handleWheel); // 移除监听器
  },
  methods: {
    getBannerList() {
      const params = {
        status: 1,
        pageNum: 1,
        photoType: 1,
        pageSize: 999
      }
      getBannerList(params).then(res => {
        this.bannerList = res.rows
      })
    },
    handleScroll() {
      const currentScrollY = document.documentElement.scrollTop;
      if(currentScrollY==0) {    //不是第一次才会执行  每次为0  0.3s 后才能进行滚动
        this.isTop = true
        this.wait = true
        setTimeout(() => {
          this.wait = false
        },100)
      }else {
        this.isTop = false
      }
      return
    },
    handleWheel(event) {
      const targetElement = this.$refs.targetElement;
      if(this.isTop && this.wait){
        event.preventDefault(); // 禁止滚动
        return;
      }
      if(this.isFirst) {
        this.$emit('show', true)
        this.fullScreen = false
        targetElement.style.height = '0'; // 高度变为0
        this.isFirst = false
        setTimeout(() => {
          this.isTop = true
          this.$emit('height', true)
        }, 1000);
      }else{
        if(this.isAnimating){  //动画中  禁用
          event.preventDefault(); // 禁止滚动
          return;
        }
        if(event.deltaY > 0 && this.fullScreen && this.isTop && !this.wait){  //全屏模式中向下加载
          this.isAnimating = true
          this.$emit('show', true)
          this.fullScreen = false
          targetElement.style.height = '0'; // 高度变为0
          setTimeout(() => {
            this.$emit('height', true)
            this.isAnimating = false
            this.isTop = true
            document.documentElement.scrollTop = 0
          }, 1000);
        }
        if(event.deltaY < 0 && !this.fullScreen && this.isTop && !this.wait){  //非全屏模式向上加载
          this.fullScreen = true
          targetElement.style.height = '100vh'; // 高度变为0
          this.isAnimating = true
          this.$emit('height', false)
          setTimeout(() => {
            this.isAnimating = false
            this.isTop = true
            this.$emit('show', false)
          }, 1000);
        }
      }
    }
  },
  computed: {
    isLogin() {
      return localStorage.getItem("userInfo") ? true : false;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .el-carousel__container{
  height: 100vh !important;
  width: 100%;
}
.banner{
  position: relative;
  width: 100%;
  height: 100vh;
  transition: height 1s;
  overflow: hidden;
  .top{
    position: absolute;
    margin: auto;
    top: 0;
    left: 0;
    right: 0;
  }
  .bannerIMG{
    height: 100vh;
    width: 100%;
  }
}
</style>