<template>

  <div class="breadcrumb_box">
    <div class="breadcrumb flex">
      <!-- <img class="breadcrumb_img" src="@/assets/image/lease/sy.png" alt=""> -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <!-- -for="(item,index) in breadList " :key="index" -->
        <el-breadcrumb-item :to="{ path: routerInfo.path }">{{ routerInfo.name }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-divider />
  </div>

</template>

<script>
export default {
  props: ["routerInfCoustom"],
  data() {
    return {
      breadList: [],
      routerInfo: {},
    };
  },
  created() {
    if (this.routerInfCoustom) {
      this.routerInfo = {
        name: this.routerInfCoustom.name,
        path: this.routerInfCoustoms.path,
      };
      return;
    }
    this.routerInfo = {
      name: this.$route.meta.name,
      path: this.$route.path,
    };
    // this.breadList = this.routerInfo;
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-breadcrumb__item{
  font-size: 22px;
}
.breadcrumb_box{
  width: 87.5rem;
  margin: auto;
}
/* 面包屑 */
.breadcrumb {
  padding-top: 1.875rem;
  align-items: center;
}

.breadcrumb_img {
  width: .875rem;
  height: .8125rem;
  margin-right: 1.25rem;
}
</style>
