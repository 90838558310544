<template>
  <div>
    <PageScroll @height="changeHeight" @show="changeShow"></PageScroll>
    <PageHd :show="false" :height="height"/>
    <div class="home" v-show="isShow">
      <div :class="height?'top_info height':'top_info'">
        <div class="num_box" ref="observedElement">
          <div class="info_box">
            <img class="icon" src="@/assets/home/top_1.png" alt="">
            <div class="info">
              <div class="num">{{ formattedValue1 }}</div>
              <div class="label">发布需求数(项)</div>
            </div>
          </div>
          <div class="info_box two">
            <img class="icon" src="@/assets/home/top_2.png" alt="">
            <div class="info">
              <div class="num"> {{ formattedValue2 }}</div>
              <div class="label">解决融资金额(亿元)</div>
            </div>
          </div>
        </div>
        <div class="money_list_box">
          <div class="swiper-wrapper">
            <div v-for="(item, index) in financingList" :key="index" class="swiper-slide list">
              {{ item }}
            </div>
          </div>
        </div>
      </div>
      <div class="model one">
        <div class="title">
          <img class="icon" src="@/assets/home/one.png" alt="">
        </div>
        <div class="content_bg">
          <!-- <img class="line" src="@/assets/home/line.png" alt=""> -->
          <div class="one_model_box">
            <div class="item" @click="jump('/security?id=guarantee')">
              <div class="active">
                <img class="act_img" src="../assets/home/one-a1.png" alt="">
                <img class="go" src="../assets/home/go.png" alt="">
              </div>
              <img class="icon" src="@/assets/home/one1.png" alt="">
              <div class="label">融资担保申请</div>
              <!-- <div class="circle"></div> -->
              <div class="num">01</div>
            </div>
            <div class="item" @click="jump('/security?id=48c02b4xiaoedaikuan7f7995b967ad')">
              <div class="active">
                <img class="act_img" src="../assets/home/one-a4.png" alt="">
                <img class="go" src="@/assets/home/go.png" alt="">
              </div>
              <img class="icon" src="@/assets/home/one4.png" alt="">
              <div class="label">小额贷款申请</div>
              <!-- <div class="circle"></div> -->
              <div class="num">02</div>
            </div>
            <div class="item" @click="jump('/factorList')">
              <div class="active">
                <img class="act_img" src="../assets/home/one-a3.png" alt="">
                <img class="go" src="../assets/home/go.png" alt="">
              </div>
              <img class="icon" src="@/assets/home/one3.png" alt="">
              <div class="label">融资保理申请</div>
              <!-- <div class="circle"></div> -->
              <div class="num">03</div>
            </div>
            <div class="item" @click="jump('/leaseList')">
              <div class="active">
                <img class="act_img" src="../assets/home/one-a2.png" alt="">
                <img class="go" src="../assets/home/go.png" alt="">
              </div>
              <img class="icon" src="@/assets/home/one2.png" alt="">
              <div class="label">融资租赁申请</div>
              <!-- <div class="circle"></div> -->
              <div class="num">04</div>
            </div>
            <div class="item" @click="jump('/security?id=yingjizhuandai')">
              <div class="active">
                <img class="act_img" src="../assets/home/one-a5.png" alt="">
                <img class="go" src="../assets/home/go.png" alt="">
              </div>
              <img class="icon" src="@/assets/home/one5.png" alt="">
              <div class="label">应急转贷申请</div>
              <!-- <div class="circle"></div> -->
              <div class="num">05</div>
            </div>
          </div>
        </div>
      </div>
      <div class="model two">
        <div class="title">
          <img class="icon" src="@/assets/home/two.png" alt="">
        </div>
        <div class="abput_box">
          <div class="left">
            <div class="label">
              <img src="@/assets/home/two_icon.png" alt="">
              {{ aboutInfo.title }}
            </div>
            <div class="content">
              {{ aboutInfo.content }}
            </div>
            <div class="look_detail" @click="goAboutDetail()">
              查看详情>>
            </div>
          </div>
          <img class="right" :src="aboutInfo.pictureOut" alt="">
        </div>
        <div class="two_bottom">
          <div class="bottom_content">
            <div class="box">
              <div class="box_title">
                <span class="num">{{ coreData.yearOfFoundation }}</span>
                <span class="unit">年</span>
              </div>
              <div class="desc">
                公司成立
              </div>
            </div>
            <div class="box">
              <div class="box_title">
                <span class="num">{{ coreData.registeredCapital }}</span>
                <span class="unit">亿</span>
              </div>
              <div class="desc">
                注册资金
              </div>
            </div>
            <div class="box">
              <div class="box_title">
                <span class="num">{{ coreData.staffCount }}</span>
                <span class="unit">人</span>
              </div>
              <div class="desc">
                干部员工
              </div>
            </div>
            <div class="box">
              <div class="box_title">
                <span class="num">{{ coreData.income }}</span>
                <span class="unit">亿</span>
              </div>
              <div class="desc">
                {{ coreData.remark }}年收入
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 合作银行 -->
      <div class="model three">
        <div class="title">
          <img class="icon" src="@/assets/home/three.png" alt="">
        </div>
        <div class="bank_list">
          <div class="list_item" v-for="(item, index) in bankList" :key="index" @click="goBankDetail(item)">
            <img class="list_img" :src="item.photo" alt="">
          </div>
        </div>
      </div>
      <!-- 底部 -->
      <PageBt></PageBt>
    </div>
  </div>
</template>

<script>
import Swiper from 'swiper' // 注意引入的是Swiper
import 'swiper/css/swiper.min.css' // 注意这里的引入
import { 
  getBankList,
  getBannerList,
  getIncreaseNum,
  getFinancingList,
  getArticleDetails,
  getCoreData
} from "@/utils/network.js";
export default {
  data() {
    return {
      aboutInfo: {},
      coreData: {},
      bankList: [],
      bannerList: [],
      orderCount: 0, // 目标值
      displayValue1: 0,         // 显示的数值
      incrementValue1: 0,        // 每步增加的值
      amount: 0, // 目标值
      displayValue2: 0,         // 显示的数值
      incrementValue2: 0,        // 每步增加的值
      financingList: [],
      isShow: false,
      height: false,
      isElementVisible: false
    }
  },
  computed: {
    // 使用计算属性来格式化数值，添加逗号分隔符
    formattedValue1() {
      return this.displayValue1.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    },
    formattedValue2() {
      return this.displayValue2.toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      });
    }
  },
  created() {
    console.log(Swiper)
    // banner
    // this.getbanner_lists();
    this.getbankList()
    this.getAboutDetail()
    this.getCoreData()
  },
  mounted() {
    this.getIncreaseNum()
    this.getFinancingList()
    this.swiperInit()
    // 创建一个 IntersectionObserver 实例
    this.observer = new IntersectionObserver(this.handleIntersection, {
      root: null, // 使用视口作为根
      rootMargin: '0px',
      threshold: 0.1 // 当元素 10% 可见时触发
    });

    // 开始观察目标元素
    this.observer.observe(this.$refs.observedElement);
  },
  beforeDestroy() {
    // 在组件销毁之前断开观察器
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  methods: {
    jump(path) {
      this.$router.push(path);
    },
    getCoreData() {
      getCoreData('guanwangzhanshishuju').then(res => {
        this.coreData = res.data
      })
    },
    goAboutDetail() {
      this.$router.push('/about')
    },
    async getAboutDetail() {
      const res = await getArticleDetails('guanyuwomen')
      console.log(res)
      this.aboutInfo = res.data
    },
    getIncreaseNum() {
      getIncreaseNum().then(res => {
        console.log(res, 'asdasdasdasd')
        this.orderCount = res.data.orderCount
        this.amount = res.data.amount
      })
    },
    getFinancingList() {
      getFinancingList().then(res => {
        this.financingList = res.data
      })
    },
    handleIntersection(entries) {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          this.isElementVisible = true;
          // this.executeMethod(); // 当元素进入视口时执行方法
          this.startCountUp1()
          this.startCountUp2()
          // 可以停止观察以防止重复触发
          this.observer.unobserve(this.$refs.observedElement);
        }
      });
    },
    changeShow(e) {
      this.isShow = e
    },
    changeHeight(e) {
      this.height = e
    },
    swiperInit() {
      this.swiper = new Swiper('.money_list_box', {
        slidesPerView: '4',
        direction: 'vertical', // 垂直切换选项
        loop: true, // 循环模式选项
        autoplay: {
          delay: 1500
        },//可选选项，自动滑动
        spaceBetween: 0
      })
    },
    startCountUp1() {
      // 设置动画持续时间为 1 秒（1000 毫秒）
      const duration = 1500; // 动画持续时间，毫秒
      const frameRate = 60;  // 帧率，FPS
      const frameCount = (duration / 1000) * frameRate;
      this.incrementValue1 = this.orderCount / frameCount;

      // 使用 setInterval 逐步增加 displayValue1 的值
      const interval = setInterval(() => {
        this.displayValue1 += this.incrementValue1;
        if (this.displayValue1 >= this.orderCount) {
          this.displayValue1 = this.orderCount; // 确保不会超过目标值
          clearInterval(interval);              // 停止计时器
        }
      }, 1000 / frameRate);
    },
    startCountUp2() {
      // 设置动画持续时间为 1 秒（1000 毫秒）
      const duration = 1500; // 动画持续时间，毫秒
      const frameRate = 60;  // 帧率，FPS
      const frameCount = (duration / 1000) * frameRate;
      this.incrementValue2 = this.amount / frameCount;

      // 使用 setInterval 逐步增加 displayValue1 的值
      const interval = setInterval(() => {
        this.displayValue2 += this.incrementValue2;
        if (this.displayValue2 >= this.amount) {
          this.displayValue2 = this.amount; // 确保不会超过目标值
          clearInterval(interval);              // 停止计时器
        }
      }, 1000 / frameRate);
    },
    getBannerList() {
      const params = {
        status: 1,
        pageNum: 1,
        photoType: 1,
        pageSize: 999
      }
      getBannerList(params).then(res => {
        this.bannerList = res.rows
      })
    },
    jumpSecurity(item) {
      this.$router.push({ path: item.path, query: { name: item.name } });
    },
    jump(path) {
      this.$router.push(path);
    },
    goBankDetail(item) {
      this.$router.push({ name: "bankDetails", query: { id: item.id } });
    },
    async getbankList() {
      const res = await getBankList()
      console.log(res);
      this.bankList = res.data;
    },
  },
};
</script>


<style lang="scss" scoped>
.banner {
  width: 100%;
  max-width: 100vw;
  height: 934px;

  // background-color: red;
  .bannerIMG {
    width: 100vw;
    height: 934px;
    object-fit: cover !important;
  }
}

::v-deep .el-carousel__container {
  height: 934px !important;
}

.home {
  .top_info {
    &.height{
      transition: all 1s;
      margin-top: 120px;
    }
    width: 100%;
    height: 265px;
    background: url('@/assets/home/index_top.png');
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .num_box {
      display: flex;
      align-items: center;
      margin-bottom: 45px;

      .info_box {
        margin-left: 260px;
        display: flex;
        align-items: center;
        &.two {
          margin-left: 30px;
        }

        .icon {
          width: 66px;
          height: 66px;
        }

        .info {
          margin-left: 20px;

          .num {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 700;
            font-size: 52px;
            color: #333333;
            width: 400px;
          }

          .label {
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 22px;
            color: #666666;
          }
        }
      }
    }

    .money_list_box {
      margin-right: 80px;
      width: 600px;
      height: 200px;
      margin-bottom: 30px;
      overflow: hidden;
      position: relative;
      .list {
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 22px;
        color: #8F6524;
        height: 50px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
  }
  .model {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    &.two{
      background: rgba($color: #FFECCE, $alpha: 0.1);
    }
    &.three{
      padding-top: 50px;
    }
    .title{
      height: 255px;
      .icon{
        width: 224px;
        height: 200px;
      }
    }
    .content_bg{
      width: 100%;
      height: 424px;
      display: flex;
      align-items: center;
      background: url('@/assets/home/one_bg.png') 100% 100%;
      position: relative;
      // .line{
      //   width: 100%;
      //   height: 2px;
      //   position: absolute;
      //   margin: auto;
      //   left: 0;
      //   bottom: 116px;
      //   right: 0;
      // }
      .one_model_box{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        .item{
          display: flex;
          align-items: center;
          width: 225px;
          height: 330px;
          justify-content: center;
          flex-direction: column;
          margin: 0 34px;
          position: relative;
          cursor: pointer;
          &:hover{
           .active{
              width: 225px;
              height: 330px;
              transition: all 0.5s;
              .go{
                transition: all 0.5s;
                width: 90px;
                height: 40px;
              }
           } 
          }
          .active{
            position: absolute;
            margin: auto;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            width: 0;
            height: 0;
            z-index: 1;
            .act_img{
              width: 100%;
              height: 100%;
            }
            .go{
              width: 0;
              height: 0;
              position: absolute;
              margin: auto;
              left: 0;
              right: 0;
              bottom: -20px;
            }
          }
          .icon{
            width: 70px;
            height: 70px;
          }
          .label{
            margin-top: 34px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 30px;
            color: #333333;
          }
          // .circle {
          //   width: 30px;
          //   height: 30px;
          //   border-radius: 50%;
          //   border: 1px solid #707070;
          //   margin-top: 45px;
          //   background: #fff;
          //   position: relative;
          //   z-index: 9;
          // }
          .num{
            font-family: AvantGardeITCbyBT-Book, AvantGardeITCbyBT-Book;
            font-weight: 400;
            font-size: 60px;
            color: #C29145;
            line-height: 60px;
            margin-top: 100px;
          }
        }
      }
    }
    .abput_box{
      width: 1390px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 540px;
      .left{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        height: 100%;
        width: 639px;
        .label{
          display: flex;
          align-items: center;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 36px;
          color: #333333;
          img{
            width: 35px;
            height: 36px;
            margin-right: 10px;
          }
        }
        .content{
          flex: 1;
          margin-top: 28px;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 24px;
          color: #333333;
          line-height: 48px;
        }
        .look_detail{
          font-weight: 400;
          cursor: pointer;
          font-size: 30px;
          color: #C29145;
          line-height: 48px;
        }
      }
      .right{
        width: 680px;
        height: 540px;
      }
    }
    .two_bottom{
      margin-top: 60px;
      width: 100%;
      height: 160px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: linear-gradient( 180deg, rgba(255,223,176,0.5) 0%, rgba(221,188,141,0.7) 94%);
      .bottom_content{
        width: 1390px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .line{
          width: 1px;
          height: 80px;
          background: #FFA213;
        }
        .box{
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .box_title{
            .num{
              font-family: D-DIN, D-DIN;
              font-weight: 700;
              font-size: 54px;
              color: #FFA213;
              line-height: 48px;
              text-align: left;
              font-style: normal;
              text-transform: none;
            }
            .unit{
              color: #333;
              font-size: 24px;
            }
          }
          .desc{
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 22px;
            color: #333333;
            line-height: 48px;
          }
        }
      }
    }
    .bank_list{
      width: 1400px;
      margin-top: 40px;
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 100px;
      cursor: pointer;
      .list_item{
        width: 268px;
        height: 150px;
        margin-bottom: 20px;
        overflow: hidden;
        display: flex;
        align-items: center;
        border: 1px solid #333;
        justify-content: center;
        .list_img{
          width: 268px;
          height: 150px;
          transition: all .5s;
          &:hover{
            width: 321.6px;
            height: 180px;
          }
        }
      }
    }
  }
}
</style>