import request from '@/utils/request'

// 发送验证码 
export function sendCode(data) {
  return request({
    url: '/sms/code',
    method: 'post',
    data
  })
}

// 注册 
export function register(data) {
  return request({
    url: '/sms/register',
    method: 'post',
    data
  })
}

// 获取个人信息 
export function getApplyDetail(id) {
  return request({
    url: '/order/order/appoint/' + id,
    method: 'get',
  })
}

// 短信登录 
export function smsLogin(data) {
  return request({
    url: '/sms/login',
    method: 'post',
    data
  })
}

// 重置密码 
export function resetPassword(data) {
  return request({
    url: '/system/user/profile/updatePwdBySms',
    method: 'put',
    data
  })
}

// 个人中心重置密码
export function userResetPassword(params) {
  return request({
    url: '/system/user/profile/updatePwd',
    method: 'put',
    params
  })
}

// 获取个人信息 
export function getUserInfo() {
  return request({
    url: '/getInfo',
    method: 'get',
  })
}

// 查询是否实名认证 
export function getRealName() {
  return request({
    url: '/basedata/auth/isVerify',
    method: 'get',
  })
}

// 密码登录 
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

// 个人实名认证 
export function authPerson(data) {
  return request({
    url: '/auth/pers',
    method: 'post',
    data
  })
}

// 企业实名认证 
export function authCompany(data) {
  return request({
    url: '/auth/corp',
    method: 'post',
    data
  })
}

// 反馈建议 
export function addAdvice(data) {
  return request({
    url: '/basedata/comment/add',
    method: 'post',
    data
  })
}

// 查询产品政策列表
export function getPolicyList(params) {
  return request({
    url: '/basedata/article/webPage',
    method: 'get',
    params
  })
}

// 获取核心数据
export function getCoreData(id) {
  return request({
    url: '/basedata/data/' + id,
    method: 'get'
  })
}

// 查询首页增长数量2
export function getIncreaseNum() {
  return request({
    url: '/offical/order/count',
    method: 'get'
  })
}

// 查询首页融资列表
export function getFinancingList(data) {
  return request({
    url: '/offical/order/ten',
    method: 'get',
    params: data
  })
}


// 查询产品政策列表
export function getBannerList(data) {
  return request({
    url: '/basedata/photos/page',
    method: 'get',
    params: data
  })
}

// 查询产品政策详情
export function getArticleDetails(id) {
  return request({
    url: '/basedata/article/' + id,
    method: 'get',
  })
}
// 查询合作银行列表
export function getBankList(data) {
  return request({
    url: '/basedata/bank/list',
    method: 'get',
    params: data
  })
}
// 查询合作银行详情
export function getBankListDetails(id) {
  return request({
    url: '/basedata/bank/' + id,
    method: 'get'
  })
}

// 查询产品列表 
export function getProductList(data) {
  return request({
    url: '/basedata/product/list',
    method: 'get',

  })
}

// 查询产品详情 
export function getProductDetails(id) {
  return request({
    url: '/basedata/product/' + id,
  })
}


// 线上预约提交 
export function addOrder(data) {
  return request({
    url: '/order/order/add',
    method: 'post',
    data
  })
}

// 预约记录列表
export function listAppoint(params) {
  return request({
    url: '/order/order/listAppoint',
    method: 'get',
    params
  })
}

// 查询详情申请信息
export function getDetailApplyInfo(id) {
  return request({
    url: '/order/order/apply/' + id,
    method: 'get'
  })
}

// 字典查询产品类型下拉
export function productQuery(query) {
  return request({
    url: '/basedata/product/list',
    method: 'get',
    params: query
  })
}

// 担保企业发起
export function addCompanyGuarantee(data) {
  return request({
    url: '/guarantee/corp/add',
    method: 'post',
    data
  })
}

// 担保私人发起
export function addPersonGuarantee(data) {
  return request({
    url: '/guarantee/pers/add',
    method: 'post',
    data
  })
}

// 租赁
export function addLease(data) {
  return request({
    url: '/order/order/add',
    method: 'post',
    data: data
  })
}

// 获取二次
export function getSecondContract(params) {
  return request({
    url: '/pettyloan/reloan/list',
    method: 'get',
    params
  })
}

export function addRepay(data) {
  return request({
    url: '/pettyloan/reloan/add',
    method: 'post',
    data: data
  })
}

// 查询某条订单的还款计划
export function getRepayPlan(params) {
  return request({
    url: '/order/repay/page',
    method: 'get',
    params
  })
}

//企业小额贷
export function addCompanyPettyloan(data) {
  return request({
    url: '/pettyloan/corp/add',
    method: 'post',
    data: data
  })
}

//个人小额贷
export function addPersonPettyloan(data) {
  return request({
    url: '/pettyloan/pers/add',
    method: 'post',
    data: data
  })
}

// 保理业务发起
export function addFactoring(data) {
  return request({
    url: '/factoring/base/add',
    method: 'post',
    data: data
  })
}

// 查询合同详细信息
export function getContractDetails (id) {
  return request({
    url: '/order/contract/' + id,
    method: 'get'
  })
}

// 获取附件列表list
export function getFileList(params) {
  return request({
    url: `/tools/info/getFiles/${params}`,
    method: 'get'
  })
}

export function getUserServeAgreementDetail(id) {
  return request({
      url: '/basedata/agreement/' + id,
      method: 'get'
  })
}

// 业务品种下拉
export function getSpeciesList(params) {
  return request({
      url: '/option/business',
      method: 'get',
      params
  })
}