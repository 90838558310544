import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import login from '@/views/login.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      name: '首页'
    }

  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      name: '登录'
    }

  },
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/register.vue'),
    meta: {
      name: '注册'
    }

  },
  {
    path: '/forget',
    name: 'forget',
    component: () => import('@/views/forget.vue'),
    meta: {
      name: '重置密码'
    }

  },

  // 下面是新的
  {
    // 租赁 选择
    path: '/lease',
    name: 'lease',
    component: () => import('@/views/lease.vue'),
    meta: {
      name: '租赁'
    }
  },
  // 金融政策
  {
    path: '/policy',
    name: 'policy',
    component: () => import('@/views/policy.vue'),
    meta: {
      name: '金融政策'
    }
  },
  // 金融政策详情
  {
    path: '/policyDetails',
    name: 'policyDetails',
    component: () => import('@/views/policyDetails.vue'),
    meta: {
      name: '金融政策详情'
    }
  },
  // 关于我们
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about.vue'),
    meta: {
      name: '关于我们'
    }
  },
  // 银行
  {
    path: '/bank',
    name: 'bank',
    component: () => import('@/views/bank.vue'),
    meta: {
      name: '合作银行'
    }
  },
  // 银行详情
  {
    path: '/bankDetails',
    name: 'bankDetails',
    component: () => import('@/views/bankDetails.vue'),
    meta: {
      name: '银行详情'
    }

  },
  // 个人中心
  {
    path: '/mine',
    name: 'mine',
    component: () => import('@/views/mine.vue'),
    meta: {
      name: '个人中心'
    }
  },

  // 担保详情
  {
    path: '/security',
    name: 'security',
    component: () => import('@/views/security.vue'),
    meta: {
      name: '金融服务详情'
    }
  },
  // 租赁产品列表
  {
    path: '/leaseList',
    name: 'leaseList',
    component: () => import('@/views/leaseList.vue'),
    meta: {
      name: '租赁产品列表'
    }
  },
  // 租赁产品列表
  {
    path: '/factorList',
    name: 'factorList',
    component: () => import('@/views/factorList.vue'),
    meta: {
      name: '保理产品列表'
    }
  },
  
  // // 测试页面
  // {
  //   path: '/test',
  //   name: 'test',
  //   component: () => import('@/views/test.vue'),
  //   meta: {
  //     name: '担保详情'
  //   }
  // },


]

const router = new VueRouter({
  history: 'hash', // 使用 hash 模式
  routes,
  scrollBehavior(to, from, savedPosition) {
    // 始终返回 { x: 0, y: 0 }
    return { x: 0, y: 0 };
  }

})

export default router
