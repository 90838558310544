<template>
  <div class="pointer">
    <div
    :class="{
      'height': height,
      'block': show,
      'header': true,
      'opacity': opacity
    }">
      <div class="left flex_aling_center" @click="goPage('/')">
        <img v-if="!show" class="logo" src="@/assets/nav_logo1.png" alt="">
        <img v-else class="logo" src="@/assets/nav_logo.png" alt="">
      </div>
      <div :class="!show?'tabs':'tabs main'">
        <div class="tabs_item flex_aling_justify_center " @click="goPage('/')">
          首页
        </div>
        <div class="tabs_item flex_aling_justify_center">
          金融服务
          <div class="nav_select">
            <div class="nav_select_item" @click="jump('/security?id=guarantee')">融资担保</div>
            <div class="nav_select_item" @click="jump('/leaseList')">融资租赁</div>
            <div class="nav_select_item" @click="jump('/security?id=48c02b4xiaoedaikuan7f7995b967ad')">小额贷款</div>
            <div class="nav_select_item" @click="jump('/factorList')">商业保理</div>
            <div class="nav_select_item" @click="jump('/security?id=yingjizhuandai')">应急转贷</div>
          </div>
        </div>
        <div class="tabs_item flex_aling_justify_center" @click="jump('/policy')">
          金融政策
        </div>
        <div class="tabs_item flex_aling_justify_center" @click="jump('/bank')">
          合作银行
        </div>
        <div class="tabs_item flex_aling_justify_center" @click="jump('/about')">
          关于我们
        </div>
        <!-- <div :class="{'tabs_active':path=='/'}" class="tabs_item flex_aling_justify_center " @click="goPage('/')">首页</div>
        <div :class="{'tabs_active':path=='/policy'}" class="tabs_item flex_aling_justify_center" @click="jump('/policy')">金融服务</div>
        <div :class="{'tabs_active':path=='/policy'}" class="tabs_item flex_aling_justify_center" @click="jump('/policy')">金融政策</div>
        <div :class="{'tabs_active':path=='/bank'}" class="tabs_item flex_aling_justify_center" @click="jump('/bank')">合作银行</div>
        <div :class="{'tabs_active':path=='/about'}" class="tabs_item flex_aling_justify_center" @click="jump('/about')">关于我们</div> -->
      </div>
      <!-- <div v-if="isLogin" :class="{'tabs_active':path=='/mine'}" @click="goPage('/mine')">个人中心</div> -->
      <div :class="!show?'isLogin':'isLogin main'" v-if="isLogin">
        <img class="avatar" src="@/assets/login/logo.png" alt="">
        <div class="name">{{ userInfo.nickName }}</div>
        <!-- <img src="@/assets/" alt=""> -->
        <div class="user_select_box">
          <div class="user_list" @click="goPage('/mine')">
            <img class="icon" src="@/assets/login/user2.png" alt="">
            个人中心
          </div>
          <div class="user_list" @click="logout">
            <img class="icon" src="@/assets/login/user1.png" alt="">
            退出登录
          </div>
        </div>
      </div>
      <div :class="!show?'login_btn':'login_btn main'" v-else @click="goPage('/login')">登录/注册</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageHd',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    height: {
      type: Boolean,
      default: false
    },
    opacity: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      path: "/",
      userInfo: {}
    };
  },
  created() {
    this.userInfo = JSON.parse(localStorage.getItem("userInfo"))
    console.log(this.userInfo, 'userInfouserInfo')
    this.path = this.$route.path;
  },
  methods: {
    goPage(path) {
      if (this.$route.path == path) {
        return;
      }
      this.$routerGo(path);
    },
    jump(path) {
      this.$router.push(path);
      // this.$router.push({
      //   path: "/list",
      //   query: { type: index, bannerType },
      //   force: true,
      // });
      // window.location.reload();
    },
    jump1(index, bannerType, isImage) {
      this.$router.push({
        path: "/list",
        query: { type: index, bannerType, isImage: isImage }
      })
    },
    logout() {
      localStorage.clear()
      this.$router.push('/')
      this.$router.go(0)
    }
  },
  computed: {
    isLogin() {
      return localStorage.getItem("token") ? true : false;
    }
  }
}
</script>

<style scoped lang="scss">
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  height: 0;
  box-shadow: 0 6px 16px rgb(65 0 0 / 16%);
  // box-shadow: 3px 3px 3px 1px rgba(0, 0, 0, 0.3);
  // height: 7.5rem;
  &.block{
    position: relative;
    height: 7.5rem;
    overflow: visible;
  }
  &.height{
    height: 7.5rem;
    transition: all 0.5s;
    position: fixed;
    overflow: visible;
    // background: rgba($color: #000, $alpha: 1);
    background: rgba($color: #fff, $alpha: 1);
  }
  &.opacity{
    height: 7.5rem;
    transition: all 0.5s;
    position: fixed;
    overflow: visible;
    background: rgba($color: #000, $alpha: 0.4);
  }
  z-index: 2002;
  width: 100%;
  padding: 0 16.25rem;
  .left {
    margin-right: 3.75rem;
    img {
      width: 23.25rem;
      height: 4rem;
    }
  }

  .tabs {
    display: flex;
    align-items: center;
    flex: 1;
    height: 100%;
    justify-content: flex-end;
    &.main{
      .tabs_item{
        color: #fff;
      }
    }
    .tabs_item {
      font-weight: 500;
      font-size: 1.375rem;
      height: 100%;
      padding: 0 3.125rem;
      color: #333;
      position: relative;
      &:hover {
        background: #C29145;
        color: #fff;
        .nav_select{
            display: flex;
          }
      }
      .nav_select{
        position: absolute;
        margin: auto;
        left: 0;
        right: 0;
        top: 120px;
        padding: 20px 0;
        // width: 220px;
        height: 355px;
        display: none;
        flex-direction: column;
        align-items: center;
        background: url('@/assets/nav_select.png');
        background-size: 100% 100%;
        .nav_select_item {
          line-height: 63px;
          text-align: center;
          width: 100%;
          font-family: Microsoft YaHei, Microsoft YaHei;
          font-weight: 400;
          font-size: 22px;
          color: #333333;
          letter-spacing: 2px;
          &:hover{
            background: url('@/assets/nav_select_item.png');
            background-size: 100% 100%;
            opacity: 1;
          }
        }
      }
    }
    .tabs_active {
      height: 100%;
      background: #C29145;
      border-radius: 0rem 0rem 0rem 0rem;
    }
  }
  .login_btn {
    width: 180px;
    height: 3.75rem;
    border-radius: .125rem .125rem .125rem .125rem;
    border: .125rem solid #333;
    display: flex;
    align-items: center;
    margin-left: 20px;
    justify-content: center;
    font-weight: 400;
    font-size: 1.375rem;
    color: #333;
    letter-spacing: .125rem;
    &.main{
      color: #fff;
      border: .125rem solid #FFFFFF;
    }
  }
  .isLogin{
    font-family: Microsoft YaHei, Microsoft YaHei;
    font-weight: 400;
    font-size: 22px;
    color: #333;
    line-height: 120px;
    letter-spacing: 2px;
    display: flex;
    align-items: center;
    position: relative;
    height: 120px;
    &:hover {
      .user_select_box{
        display: flex;
      }
    }
    .avatar{
      width: 60px;
      height: 60px;
      margin-right: 10px;
    }
    .name{
      width: 170px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }
    &.main{
      color: #fff;
    }
    .user_select_box{
      position: absolute;
      height: 185px;
      width: 300px;
      background: #fff;
      margin: auto;
      display: none;
      // display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      bottom: -185px;
      .user_list{
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 22px;
        color: #333;
        letter-spacing: 2px;
        .icon{
          width: 24px;
          height: 24px;
          margin-right: 30px;
        }
      }
    }
  }
}
</style>