import router from '@/router/index'
import store from '@/store/index.js'






const whiteList = ['/login'] // no redirect whitelist
// 路由拦截器
router.beforeEach(async (to, from, next) => {
   






  next()
  // 如果有token
  // if (hasToken) {

  //   if (to.path === '/login') {
  //     NProgress.done() 
  //   }
  //   else {

  

  //   }
  //   // 如果没有
  // } else {

  //   if (whiteList.indexOf(to.path) !== -1) {
  //     // in the free login whitelist, go directly
  //     next()
  //   } else {
  //     // other pages that do not have permission to access are redirected to the login page.
  //     next(`/login`)
  //     NProgress.done()
  //   }
  // }

})

router.afterEach(() => {
  // finish progress bar

})
